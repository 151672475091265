.profile-picture {
  &__image-preview-container {
    position: relative;
  }

  &__image-preview {
    object-fit: cover;
    object-position: top;
    max-height: 35vmin;
    width: 100%;
  }

  &__delete-image-preview {
    position: absolute !important;
    right: 0;
    top: 0px;
    z-index: 99;
  }

  &__video-preview-container {
    position: relative;

    div {
      overflow: hidden;
      padding-top: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__video-preview {
    object-fit: cover;
    object-position: top;
    max-height: 35vmin;
    width: 100%;
  }

  &__delete-video-preview {
    position: absolute !important;
    right: 0;
    top: 0px;
    z-index: 99;
  }
}
