.breadcrumbs {
  text-decoration: none;

  &.active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &--disabled {
    pointer-events: none;
  }

  &__container {
    &--disabled {
      pointer-events: none;
    }
  }

  &__icon {
    font-size: 1rem;

    &--margin-right {
      margin-right: 0.5rem;
    }
  }

  &__text {
    font-size: 0.95rem;
  }
}
