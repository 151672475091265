@mixin flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.login {
  &__container {
    @include flex-center();

    background-color: #ffb74d;
    height: 100vh;
    min-height: 750px;
    padding: 16px;
  }

  &__box {
    border-radius: 32px;
    height: 700px;

    &--left {
      background-color: white;
      display: flex;
      flex-direction: column;
      grid-row: 1;
      justify-content: space-between;
      padding: 16px 32px;
      z-index: 1;
    }

    &--right {
      background-color: black;
      display: none;
      grid-row: 1;

      img {
        display: block;
        margin-left: 10vw;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    gap: 8px;

    h1 {
      font-size: 1rem;
    }

    img {
      display: block;
      width: 30px;
    }
  }

  &__content {
    margin: 0 auto;
    width: 80%;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 16px;
      text-align: center;
    }

    & > p {
      font-size: 0.85rem;
      margin-bottom: 48px;
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;

    p {
      color: gray;
      font-size: 0.7rem;
    }

    a {
      color: #ffa726;
      font-size: 0.7rem;
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 960px) {
  .login {
    &__container {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
    }

    &__box {
      &--left {
        grid-column: 1 / 6;
      }

      &--right {
        @include flex-center();

        grid-column: 5 / 11;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .login {
    &__box {
      &--left {
        grid-column: 2 / 6;
      }

      &--right {
        grid-column: 5 / 10;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .login {
    &__box {
      &--left {
        grid-column: 3 / 6;
      }

      &--right {
        grid-column: 5 / 9;
      }
    }
  }
}
