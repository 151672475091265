.empty {
  &__container {
    height: 270px;
    margin: 0 auto;
    position: relative;
    width: 200px;
  }

  &__text {
    top: 85%;
    color: rgba($color: #aeb5bb, $alpha: 0.4);
    left: 50%;
    margin: 0;
    position: absolute !important;
    transform: translateX(-50%);
  }
}
