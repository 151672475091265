.not-found {
  &__container {
    margin: 0 auto;
    padding: 1rem 0;
    position: relative;
    width: 100%;
  }

  &__animation {
    margin-bottom: 2rem;
  }

  &__button {
    bottom: 0;
    left: 50%;
    margin: 0;
    position: absolute !important;
    transform: translateX(-50%);
  }
}

@media (min-width: 600px) {
  .not-found {
    &__container {
      width: 70%;
    }
  }
}

@media (min-width: 1200px) {
  .not-found {
    &__container {
      width: 60%;
    }
  }
}
