// element
.ql-editor {
  height: 300px;
  resize: vertical;
}

.ql-snow .ql-picker.ql-font {
  width: 140px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 300px;
  overflow: auto;
}

// font family
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value);
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value);
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="comic-sans-ms"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="comic-sans-ms"]::before {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  content: "Comic Sans";
}

.ql-font-comic-sans-ms,
.ql-font-comic-sans-ms * {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  font-family: "Courier New", Courier, monospace;
  content: "Courier New";
}

.ql-font-courier-new,
.ql-font-courier-new * {
  font-family: "Courier New", Courier, monospace;
}

.ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  content: "Helvetica";
}

.ql-font-helvetica,
.ql-font-helvetica * {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value="rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="rubik"]::before {
  font-family: "Rubik", sans-serif;
  content: "Rubik";
}

.ql-font-rubik,
.ql-font-rubik * {
  font-family: "Rubik", sans-serif;
}

.ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
  font-family: "Times New Roman", Times, serif;
  content: "Times New Roman";
}

.ql-font-times-new-roman,
.ql-font-times-new-roman * {
  font-family: "Times New Roman", Times, serif;
}

// font size
.ql-picker {
  &.ql-size {
    .ql-picker-label,
    .ql-picker-item {
      &::before {
        content: attr(data-value) !important;
      }
    }
  }
}
