.forbidden {
  &__container {
    margin: 0 auto;
    padding: 1rem 0;
    position: relative;
    width: 100%;
  }

  &__animation {
    margin-bottom: 3rem;
  }

  &__button {
    top: 90%;
    left: 50%;
    margin: 0;
    position: absolute !important;
    transform: translateX(-50%);
  }

  &__text {
    top: 90%;
    color: rgba($color: #aeb5bb, $alpha: 0.4);
    left: 50%;
    margin: 0;
    position: absolute !important;
    transform: translateX(-50%);
  }
}

@media (min-width: 600px) {
  .forbidden {
    &__container {
      width: 50%;
    }
  }
}

@media (min-width: 1200px) {
  .forbidden {
    &__container {
      width: 30%;
    }
  }
}
